.typingIndicator {
    display: flex; /* Ensures that the dots are aligned horizontally */
    justify-content: flex-start; /* Align dots to the start */
    align-items: center; /* Vertically center the dots */
    margin-top: 10px; /* Give some space above the dots */
  }
  
  .typingIndicatorBubbleDot {
    width: 8px;
    height: 8px;
    margin-right: 6px; /* Space between dots */
    background-color: #57585a;
    border-radius: 50%;
    display: inline-block;
    animation: bounce 1.3s infinite; /* Ensure the animation is properly applied */
  }
  
  .typingIndicatorBubbleDot:nth-of-type(2) {
    animation-delay: 0.15s; /* Delay for second dot */
  }
  
  .typingIndicatorBubbleDot:nth-of-type(3) {
    animation-delay: 0.3s; /* Delay for third dot */
  }
  
  @keyframes bounce {
    0%, 60%, 100% {
      transform: translateY(0);
    }
    30% {
      transform: translateY(-6px); /* This controls the bounce height */
    }
  }
  